<template>
  <v-theme-provider dark>
    <base-section id="info-alt" style="background-color: #232526 !important">
      <v-responsive class="mx-auto" max-width="1400" height="450">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="3">
              <base-img
                :src="require('@/assets/logo-orion.png')"
                class="mt-0"
                contain
                width="250"
                style="height: 55px"
              />
            </v-col>

            <!-- <v-col cols="12" md="3">
              <base-info-card title="Kontak" />

              <base-business-contact dense />
            </v-col> -->

            <v-col cols="12" md="3">
              <info-features />
            </v-col>

            <v-col cols="12" md="2">
              <product-features />
            </v-col>
            <v-col cols="12" md="3">
              <address-features />
            </v-col>
            <v-col cols="12" md="1" class="text-right">
              <v-btn
                rounded
                outlined
                small
                color="#FFFFFF"
                dark
                @click="scrollToTop"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <a
                href="https://id.linkedin.com/company/orionpenjaminan"
                target="_blank"
              >
                <v-btn class="ml-10" fab dark large color="primary">
                  <v-icon dark>mdi-linkedin</v-icon>
                </v-btn>
              </a>
            </v-col>
            <v-col cols="12" md="3">
              <p class="white--text body-2">
                PT Orion Penjaminan Indonesia merupakan Perusahaan Penjaminan
                yang didirikan pada tanggal 12 April 2023 yang bertujuan untuk
                melayani penjaminan atas kewajiban finansial para pelaku usaha
                atau nasabah baik jasa keuangan perbankan maupun non perbankan
                di Indonesia
              </p>
            </v-col>
            <v-col cols="12" class="mt-2" md="2">
              <img
                :src="require('@/assets/ojk.png')"
                class="mt-0"
                contain
                height="80"
                width="100%"
              />
            </v-col>
            <v-col cols="12" class="mt-2" md="2">
              <img
                :src="require('@/assets/asippindo2.png')"
                class="mt-0"
                contain
                height="80"
                width="100%"
              />
            </v-col>
            <v-col cols="12" class="mt-2" md="2">
              <base-img
                :src="require('@/assets/penjaminan_aman.jpg')"
                class="mt-0"
                contain
                height="80"
                width="100%"
                style="background-color:white"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "SectionBusinessInfo",

  components: {
    InfoFeatures: () => import("@/components/InfoFeatures"),
    ProductFeatures: () => import("@/components/ProductFeatures"),
    AddressFeatures: () => import("@/components/AddressFeatures"),
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="sass">
#info-alt a
  text-decoration: none
</style>
